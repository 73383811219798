// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../imgs/header.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-background {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n}\n\n.table-badge {\n  line-height: 1.8rem !important;\n  font-size: 14px;\n}\n\n.table-padding {\n  padding-top: 0px;\n}\n\n.table > :not(caption) > * > * {\n  padding: 0.7rem 1.5rem !important;\n}\n\n.xaxisbar text {\n  font-size: 1px;\n}", "",{"version":3,"sources":["webpack://./src/components/styles.scss"],"names":[],"mappings":"AAEA;EACE,yDAAA;EACA,2BAAA;AAAF;;AAGA;EACE,8BAAA;EACA,eAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,iCAAA;AAAF;;AAGA;EACE,cAAA;AAAF","sourcesContent":["@import url(\"https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css\");\n\n.header-background{\n  background-image: url(\"/imgs/header.png\");\n  background-position: center;\n}\n\n.table-badge{\n  line-height: 1.8rem !important;\n  font-size: 14px;\n}\n\n.table-padding {\n  padding-top: 0px;\n}\n\n.table > :not(caption) > * > * {\n  padding: 0.7rem 1.5rem !important;\n}\n\n.xaxisbar text {\n  font-size: 1px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
