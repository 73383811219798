import "./styles.scss";
import React from "react";
import bias from "../imgs/bias.jpg";
import HeatMap from "./HeatMap";
import { capitalize } from "@mui/material";
import {contColor as color} from "../utils/colorMaps";

interface Props {
    census: { [key: string]: number };
    loadingCensus: boolean;
    profession: string;
    setProfession: (value: string) => void;
}

const Introduction: React.FunctionComponent<Props> = ({census, loadingCensus, profession, setProfession}: Props) => {

    return <section className="mt-6">
        {/* <!-- Content --> */}
        <div
            className="container-lg max-w-screen-xl position-relative text-lg-start pt-5 pb-5 pt-lg-6">
            <h1 className="ls-tight font-bolder display-6 mb-5">Introduction</h1>
            <hr/>

            <div className="bg-white position-relative d-flex p-2 rounded shadow-sm mb-4" role="alert">
                <div className="w-1 bg-warning rounded-pill"/>
                <div className="ms-4 py-2 d-flex">
                    <div className="">
                        <span className="d-block font-semibold text-heading">Before you start!</span>
                        <p className="text-sm">
                            This report assumes basic prior knowledge on how Language Models work.
                            If you are not familiar with them, you may consider taking a look at <a href="https://towardsdatascience.com/bert-explained-state-of-the-art-language-model-for-nlp-f8b21a9b6270" className="alert-link">this resource</a>.
                        </p>
                    </div>
                </div>
            </div>

            <p className="mb-3">As applications of Artificial Intelligence in real-world scenarios increase, there has been growing concern about their ability to reproduce social biases and stereotypes
                that could affect decision-making processes <a href={"https://proceedings.neurips.cc/paper/2016/file/a486cd07e4ac3d270571622f4f316ec5-Paper.pdf"}>(Bolukbasi et al., 2016)</a>. Among such systems, language models (LMs) are well-known because of their state-of-the-art performance when dealing with Natural Language Processing (NLP) tasks <a href={"https://proceedings.neurips.cc/paper/2017/file/3f5ee243547dee91fbd053c1c4a845aa-Paper.pdf"}>(Vaswani et al., 2017)</a>.</p>

                <p className="mb-3">Language models map words within sentences to high-dimensional vectors, also called embeddings. What makes them particularly interesting is that the geometry between embeddings encapsulates semantic relationships of the corresponding words.
                Vectors close to each other correspond to similar words <a href={"https://arxiv.org/pdf/2003.07278.pdf"}>(Liu et al., 2020)</a>, and the same word when used in different contexts will have remote representations. The latent space comprising these vectors is then used to solve
                downstream tasks such as filling missing words, text classification, question answering, etc. <a href={"https://papers.nips.cc/paper/2020/hash/1457c0d6bfcb4967418bfb8ac142f64a-Abstract.html"}>(Brown et al., 2020)</a> One of such models, BERT <a href={"https://arxiv.org/pdf/1810.04805.pdf"}>(Devlin et al., 2018)</a>, is trained on a huge corpus of books and Wikipedia articles. First, it splits the sentences into tokens (similar to words). Then,
                    it randomly hides some of them by replacing them with the [MASK] token. Finally, it tries to predict the hidden words behind the [MASK] tokens.</p>

              <p className="mb-3">Ensuring such systems behave fairly across demographic groups is an open problem <a href={"https://arxiv.org/pdf/2106.13219.pdf"}>(Lian et al., 2021)</a>. In this work, we motivate through visualization what language models have learned about gender. More specifically, we explore the relations
                discovered by the model between gender and professions.</p>

            <p>Our work was motivated by the <a href="https://pair.withgoogle.com/explorables/fill-in-the-blank/">blog post by Adam Pearce</a> showing what language models have learned about specific names (e.g., person names). This project builds on his work and examines gender information encoded in the contextualized embeddings generated by such models. Our <strong>main contribution is the visual comparison between the real-world data and the representations</strong> (e.g., predictions, embeddings) created by language models.</p>

            {/* <!-- Gender in real world --> */}
            <h4 className="ls-tight font-bolder mb-5 mt-10 text-lg">Gender in the real world</h4>
            <div className="row g-10 g-lg-20 justify-content-around align-items-center">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-body" style={{paddingTop: "0.3em", paddingBottom: "0.3em"}}>
                            <div className="table-responsive">
                                <table className="table table-nowrap">
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col" style={{fontSize: "14px"}}>Profession</th>
                                        <th scope="col" style={{fontSize: "14px"}}>Females (%)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td data-label="Profession">
                                            <span style={{fontSize: "16px"}}>Electrician</span>
                                        </td>
                                        <td data-label="Females">
                                            <span className="badge table-badge" style={{
                                                backgroundColor: color(census["electrician"]*100),
                                                color: "#ffffff !important"
                                            }}>1.7%</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td data-label="Profession">
                                            <span style={{fontSize: "16px"}}>{capitalize(profession)}</span>
                                        </td>
                                        <td data-label="Females">
                                            <span className="badge table-badge" style={{
                                                backgroundColor: color(census[profession]*100)
                                            }}>{(census[profession]*100).toFixed(1)}%</span>
                                        </td>
                                    </tr>
                                    <tr style={{borderBottom: "0px solid #ffffff"}}>
                                        <td data-label="Profession">
                                            <span style={{fontSize: "16px"}}>Secretary</span>
                                        </td>
                                        <td data-label="Females">
                                            <span className="badge table-badge" style={{color: "#ffffff !important",
                                                backgroundColor: color(census["secretary"]*100)
                                            }}>96.4%</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col mb-4">
                    <p className="mb-3">Although parity is increasing according to <a href={"https://www.europarl.europa.eu/RegData/etudes/BRIE/2020/659307/EPRS_BRI(2020)659307_EN.pdf"}>recent reports</a>, the reality is that many professions are still performed unequally between men and women. In the following chart,
                        we present a selection of professions and the percentage of females occupying them in 2021, as reported by the <a href={"https://www.bls.gov/cps/cpsaat11.htm"}>USA Labor Force Statistics</a>. For instance, electrician is the most male dominated
                        profession (1.7% females), opposed to secretary which is dominated by a 96.4% of females. By hovering over a square, you can see the corresponding profession and the female participation percentage.</p>
                    <p className="">{"Throughout the dashboard, we will use the following color scale to encode female participation. Blue represents low female participation, and yellow indicates high female participation. Moreover, in order to ensure uniformity, the profession you select within a component will propagate through the whole dashboard. For example, by clicking on a square on the spectrum below, the corresponding profession will also be highlighted in all upcoming visualizations. The profession currently chosen is "+capitalize(profession)+"."}</p>
                </div>

                {!loadingCensus && <HeatMap census={census} profession={profession} setProfession={setProfession}/>}

                {!loadingCensus && <p className={"text-xs mt-1"} style={{textAlign: "center"}}>This plot represents a selection of professions and the percentage of women working on them in the US.
                </p>}

            </div>

        </div>
    </section>
};

export default Introduction;