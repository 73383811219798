import "./styles.scss";
import React from "react";
import bias from "../imgs/bias.jpg";
import HeatMap from "./HeatMap";

const Disucssion = () => {

    return <section className="mt-6">
        {/* <!-- Content --> */}
        <div
            className="container-lg max-w-screen-xl position-relative text-lg-start pt-5 pt-lg-6">
            <h1 className="ls-tight font-bolder display-6 mb-5">Discussion</h1>
            <hr/>

            <div
                className="d-flex bg-white align-items-center px-5 py-5 rounded-3 shadow font-semibold text-heading mb-3"
                role="alert">
                <div className="d-flex justify-content-center" style={{textAlign: "center", width: "100%"}}>
                    <p>
                        <span className={"text-lg"} style={{paddingRight: "0.3em"}}>BERT's predictions only partially match the real-world data on female/male-dominated professions.</span>
                    </p>
                </div>
            </div>

            <div
                    className="d-flex bg-white align-items-center px-5 py-5 rounded-3 shadow font-semibold text-heading mb-3"
                    role="alert">
                    <div className="d-flex justify-content-center" style={{textAlign: "center", width: "100%"}}>
                        <p>
                            <span className={"text-lg"} style={{paddingRight: "0.3em"}}>BERT has a strong male bias in lower layers and becomes more nuanced in later ones.</span>
                        </p>
                    </div>
                </div>

                <div
                    className="d-flex bg-white align-items-center px-5 py-5 rounded-3 shadow font-semibold text-heading mb-3"
                    role="alert">
                    <div className="d-flex justify-content-center" style={{textAlign: "center", width: "100%"}}>
                        <p>
                            <span className={"text-lg"} style={{paddingRight: "0.3em"}}>The embedding space makes strong assertions about highly stereotyped professions.</span>
                        </p>
                    </div>
                </div>




            <p className="mb-3">Our work tries to explore, through visualization, a potential relationship between gender bias in language models and real-world demographics.
            Followingly, we will revisit the main insights we gathered from the visualizations.
            However, we want to emphasize that this dashboard is of an exploratory nature.
            Hence we strongly encourage the reader to interact with the visualizations and come to own conclusions.</p>

            <p className="mb-3">We have explored
the assumptions BERT makes when it comes to guess who has a given profession. We notice that for very male-dominated professions, the model is very likely to guess that the profession is performed by a male. However, for very female-dominated occupations, no strong tendency can be observed.
Nevertheless, it is notable that the professions for which BERT strongly assumes that it is performed by a female, tend to be professions with strong gender connotations (e.g., nurse, maid, housekeeper, receptionist, secretary, etc.).
                </p>

                <p className="mb-3">Furthermore, we use visualizations to explore how the contextualized embeddings relate to each other.
                We exploit the masked language modelling strategy used to train BERT, to see the latent representation the model would generate for a mask token in sentences like
                    <span className={"font-code"}>"[MASK] is a [PROFESSION]"</span>.
                    We observe that BERT maps all professions close to the male cluster in lower layers, and only in the very last layers becomes nuanced.
                    Similarly to the previous insight, we notice that BERT maps professions with strong female gender connotations close to the female cluster.
                </p>


                <p className="mb-3">We also observe interesting outliers such as "cook" that has a latent representation for the mask very close to "she" even though
                it is a male-dominated profession in the real-world.
            </p>


            <p className="mb-3">Our work aims to motivate that real-world demographics may play an important role in the learned behavior of language models if the real world is mirrored in the language model's training data.
                However, whether or not such real-world biases should be reflected in language models is subject to further research and discussion.
            </p>


        </div>
    </section>
};

export default Disucssion;