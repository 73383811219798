import "./styles.scss";
import React, {useEffect, useState} from "react";
import bias from "../imgs/gpt3.svg";
import doctor from "../imgs/doctor.svg";
import nurse from "../imgs/nurse.svg";
import he from "../imgs/he.svg";
import she from "../imgs/she.svg";

import HeatMap from "./HeatMap";
import ProbsScatterPlot from "./ProbsScatterPlot";
import Legend from "./Legend";
import PairBarChart from "./PairBarChart";
import ProfessionList from "./DynamicGenderBias/ProfessionList";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {deepCopyArray} from "../utils";

interface Props {
    embeddings: any[];
    probs: { census: number, he_prob: number, she_prob: number, profession: string }[];
    loadingProbs: boolean;
    census: {[key:string]: any};
    loadingCensus: boolean;
    profession: string;
    setProfession: (value: string) => void;
}

enum Sort {
    Alphabet = "Alphabet",
    FemaleDominated = "Female dominated",
    MaleDominated = "Male dominated"
}

const BiasLMs: React.FunctionComponent<Props> = ({embeddings, probs, loadingProbs, census, loadingCensus, profession, setProfession}: Props) => {

    //const [activeProfession, setActiveProfession] = useState("")
    const [activeCensus, setActiveCensus] = useState(0)
    const [activeHeProb, setActiveHeProb] = useState(0)
    const [activeSheProb, setActiveSheProb] = useState(0)
    const [activeProfession, setActiveProfession] = useState("");

    const [search, setSearch] = useState("")

    useEffect(() => {
        let prof = activeProfession;
        if (activeProfession === "") {
            prof = profession;
        }
        setActiveCensus(probs.filter(p => p.profession === prof)[0]['census'])
            setActiveHeProb(probs.filter(p => p.profession === prof)[0]['he_prob']*100);
            setActiveSheProb(probs.filter(p => p.profession === prof)[0]['she_prob']*100);
    }, [activeProfession, profession]);

    // Sorting for list
    const [sortedProbs, setSortedProbs] = useState(probs)
    const [sort, setSort] = useState<string>(Sort.FemaleDominated);
    useEffect(() => {
        if (sort === Sort.Alphabet) {
            setSortedProbs(deepCopyArray(probs.sort((a, b) => a.profession.localeCompare(b.profession))));
        } else if (sort === Sort.FemaleDominated) {
            setSortedProbs(deepCopyArray(probs.sort((a, b) => a.profession.localeCompare(b.profession)).sort((a: any, b: any) => {
                if (a.census === b.census) {
                    return 0;
                }
                return a.census > b.census ? -1 : 1;
            })));
        } else if (sort === Sort.MaleDominated) {
            setSortedProbs(deepCopyArray(probs.sort((a, b) => a.profession.localeCompare(b.profession)).sort((a: any, b: any) => {
                if (a.census === b.census) {
                    return 0;
                }
                return a.census < b.census ? -1 : 1;
            })));
        }

    }, [probs, sort]);


    function handleSelection(professionSel) {
        setProfession(professionSel);
    }

    return <section className="mt-6">
        {/* <!-- Content --> */}
        {/* <!-- Gender bias --> */}
        <div
            className="container-lg max-w-screen-xl position-relative text-lg-start pt-5 pb-5 pt-lg-6">
            <div className="row g-10 g-lg-20 justify-content-around align-items-center">
                <div className="col">
                    <h4 className="ls-tight font-bolder mb-5 mt-1 text-lg">Gender Bias in Language Models</h4>
                    <p className="mb-3">Since machine learning models are being deployed in safety-critical applications such as legal systems, health care, search engines, etc., it is of utmost importance to ensure transparency, robustness and parity for different demographic groups.
                    Previous work on language models has revealed they propagate social biases present in the training corpora <a href={"https://arxiv.org/pdf/1812.08769.pdf"}>(Swinger et al., 2019)</a>.</p>
                    <p>On the right, we can see an example in which by simply changing the pronoun from "he" to "she", the text generation tool (<a href={"https://openai.com/blog/openai-api/"}>GPT-3</a>) infers different occupations. Men are assumed to work as doctors, whereas women are supposed to be nurses in the same context. These predictions encode human stereotypes about professions.</p>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="p-2 justify-content-center">
                            <img alt="..."
                                 src={bias}
                                 style={{imageRendering: "-webkit-optimize-contrast"}}/>
                        </div>
                        <div className="card-body" style={{paddingTop: "0px"}}>
                            <p className="text-xs">
                                Text generation by <a href={"https://openai.com/blog/openai-api/"}>GPT-3</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row g-4 justify-content-around align-items-center mt-3">
                <div className="col-lg-3">
                    <img alt="..."
                         src={doctor}
                         style={{imageRendering: "-webkit-optimize-contrast"}}/>
                </div>
                <div className="col-lg-3">
                    <img alt="..."
                         src={nurse}
                         style={{imageRendering: "-webkit-optimize-contrast"}}/>
                </div>
                <div className="col-lg-3" >
                    <img alt="..."
                         src={he}
                         style={{imageRendering: "-webkit-optimize-contrast"}}/>
                </div>
                <div className="col-lg-3">
                    <img alt="..."
                         src={she}
                         style={{imageRendering: "-webkit-optimize-contrast"}}/>
                </div>
            </div>

            <p className="text-xs" style={{textAlign: "center", paddingTop: "1em"}}>
                Text completion by BERT. We display the 10 most likely words and their respective probabilities. Visualization inspired by those from <a href={"https://pair.withgoogle.com/explorables/fill-in-the-blank/#:~:text=Large%20language%20models%20are%20making,and%20turn%20captions%20into%20images.&text=This%20page%20is%20hooked%20up,trained%20on%20Wikipedia%20and%20books."}>(Pearce, 2021)</a>.
            </p>

            <div
                className="d-flex bg-white align-items-center position-relative px-5 py-5 rounded-3 shadow font-semibold text-heading mt-6"
                role="alert">
                <div className="d-flex align-items-center">
                    <div className="w-16 text-lg">
                        <div className="icon icon-shape bg-warning text-white rounded-circle">
                            <i className="bi bi-question-lg"/>
                        </div>
                    </div>
                    <p className={"text-lg font-bold"} style={{paddingLeft: "3em"}}>Is bias in Language Models related to the real-world gender distribution for different professions?</p>
                </div>
            </div>

            {/*<div className="row justify-content-around align-items-center mt-6">
                <h1 className="ls-tight font-bolder display-12 mb-5">DistilBERT</h1>
                <p className="">
                In our analysis, we will focus on the language model DistilBERT, which is a reduced version of BERT. DistilBERT makes use of the Transformer architecture, an attention mechanism that learns contextual relations between words in a text. Opposed to directional models, which read the text input sequentially (left-to-right or right-to-left), the encoder of DistilBERT reads the entire sequence of words at once, and is therefore considered bidirectional. In order to avoid the directional approach of most Transformer-based models, DistilBERT is trained by randomly replacing 15% of the words in each sequence with a [MASK] token. 
                The aim of the model is then to predict the original value of the masked words, based on the context provided by the other, non-masked words in the sequence. 
                </p>
            </div>*/}

            <div className="row justify-content-around align-items-center mt-10">
                <h1 className="ls-tight font-bolder display-12 mb-5">Analysis</h1>
                <hr/>
                <p className="mb-3">
                Some previous work has investigated the relationship between gendered word embeddings and occupation participation. 
                In order to quantify gender bias as it relates to professions in contemporary media, <a href={"https://www.pnas.org/doi/full/10.1073/pnas.1720347115"}>Garg et al. (2018)</a> train word2vec on the Google News dataset to produce word embeddings for words representing each gender (male and female), and calculate the distance to the word embeddings of various occupations.
                <a href={"https://arxiv.org/pdf/1804.09301.pdf"}> Rudinger et al. (2018)</a> explore gender bias in coreference resolution systems by analyzing the extent to which a coreference system prefers to match female pronouns with a given occupation over male pronouns.
                Both papers find a correlation between occupation participation and gender bias in language models.
                </p>
                <p className="mb-3">
                We aim to contribute to this field of research by exploring the contextualized embedding space of gender pronouns and occupations via various visualizations. In order to show that the bias exerted in language models can have impact on real-world systems, we use a state-of-the-art language model called BERT, which many organizations are using to fine-tune for more specific purposes (e.g., patentBERT, docBERT, SciBERT, etc.).
                We exploit how BERT is trained using masking to create sentences of the form “[MASK] is a [PROFESSION]” (e.g., “[MASK] is a nurse”), and look at how different professions affect the contextual embedding of the mask.
                    Moreover, we will also explore the probability of the masks being replaced by “she” and “he” respectively. Finally, we analyze how the results relate to real-life demographics.
                </p>
                    <p className="mb-3">To generate our insights we start from the <a href={"https://uclanlp.github.io/corefBias/overview"}>WinoBias dataset</a>. We then manually included more professions and updated demographic data with more recent figures using their original source, the <a href={"https://www.bls.gov/cps/cpsaat11.htm"}>USA Labor Force Statistics</a>. Due to the lack of publicly available data, we will treat gender as binary in our experiments. Note also this distribution only represents professions in the USA.
                </p>
                <p>
                The questions we explore are: <strong>What assumptions do LMs make about gender in professions? What relation does it have with the real-world distribution?</strong>
                </p>
            </div>

            <div className="row justify-content-around align-items-center mt-6">
                <h4 className="ls-tight font-bolder mb-5 mt-2 text-xl">What probability does a LM assign to a woman having a certain profession?</h4>

                <div
                    className="d-flex bg-white align-items-center px-5 py-5 rounded-3 shadow font-semibold text-heading mb-3"
                    role="alert">
                    <div className="d-flex justify-content-center" style={{textAlign: "center", width: "100%"}}>
                        <p>
                            <span className={"text-lg"} style={{paddingRight: "0.3em"}}>Probability of "SHE" filling the mask in sentences like:</span><span className={"text-lg font-code"}>[MASK] is a [PROFESSION].</span>
                        </p>
                    </div>
                </div>

                <p className={"mb-3"}>This plot shows the probability BERT assigns to "she" filling the [MASK], i.e. the probability with which the model would replace the mask such that "<span className={"font-code"}>[MASK] is a [PROFESSION]</span>" becomes "<span className={"font-code"}>She is a [PROFESSION]</span>", for all the professions in our dataset. The probabilities are normalized to add up to 100%.
                    We put this into perspective by plotting it against ground truth demographics to see whether or not there is a relationship between the two variables.
                </p>

                <p className={"mb-6"}>By hovering over a dot, or by selecting a specific profession in the filter tab, you can see the concrete probabilities for "he" and "she", as well as the ground truth female participation.
                </p>
                
                <div className="col-3">
                    <div className="card">
                        <div className="card-body" style={{paddingBottom: "0.5rem"}}>
                            <FormControl size="small" sx={{paddingBottom: 1, width: "100%"}}>
                                <InputLabel id="id2" sx={{}}>Sort by</InputLabel>
                                <Select
                                    labelId="id"
                                    id="id2"
                                    value={sort}
                                    label="Sort by"
                                    onChange={(event) => setSort(event.target.value)}
                                >
                                    <MenuItem value={Sort.Alphabet} id={Sort.Alphabet}>Alphabet</MenuItem>
                                    <MenuItem value={Sort.FemaleDominated} id={Sort.FemaleDominated}>Female
                                        dominated</MenuItem>
                                    <MenuItem value={Sort.MaleDominated} id={Sort.MaleDominated}>Male
                                        dominated</MenuItem>

                                </Select>
                            </FormControl>
                            <TextField id="outlined-basic" label="Search" variant="outlined" size={"small"}  sx={{marginBottom: 1, width: "100%"}} onChange={t => setSearch(t.target.value)} placeholder={"Search..."} value={search}/>
                            <ProfessionList embeddings={probs.map(p => {return {...p, census: p['census']/100}})} selected={profession} handleToggle={handleSelection} height={400} search={search} />
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="card">
                        <div className="card-body" style={{}}>
                    {!loadingProbs && <div>
                        <ProbsScatterPlot data={probs.map(d => {
                            return {...d, census: d.census / 100}
                        })} activeProfession={activeProfession} setActiveProfession={setActiveProfession} referenceProfession={profession} setReferenceProfession={setProfession}/>
                        <p className={"text-xs"} style={{textAlign: "center"}}>Y-axis represents the probability of the token "[MASK]" being replaced by "she" by the model in sentences of the shape "[MASK] is a [PROFESSION]".
                            </p>
                        <p className={"text-xs"} style={{textAlign: "center"}}>
                            X-axis represents ground truth percentage of females in a profession.
                            </p>
                    </div>}
                        </div>
                    </div>
                </div>

                <div className={"col"}>
                    <div className="card">
                        <div className="card-body" style={{}}>
                            <p style={{fontSize: "14px", textAlign: "center", color: "black", paddingBottom: "10px"}}>Color represents real world census data</p>
                    {!loadingCensus && <Legend census={census}/>}
                        </div>
                    </div>

                    <div className="card mt-5">
                        <div className="card-body" style={{}}>
                            {(activeProfession !== "" || profession !== "") && <><p className="text-lg text-dark" style={{textAlign: "center"}}>Selected profession: <span className="font-bold">{(activeProfession !== "" ? activeProfession : profession).toUpperCase()}</span></p>
                                <div className="row justify-content-around align-items-center mt-6">
                                    <div className={"col"}>
                                        <PairBarChart values={[{title: "Females", value: activeCensus}, {title: "Males", value: 100-activeCensus}]} title={"Real World"} census={activeCensus}/>
                                    </div>
                                    <div className={"col"}>
                                        <PairBarChart values={[{title: "Prob. \"she\"", value: (activeSheProb/(activeSheProb+activeHeProb))*100}, {title: "Prob. \"he\"", value: (activeHeProb/(activeSheProb+activeHeProb))*100}]} title={"Language Model"} census={activeCensus}/>
                                    </div>
                                </div></>}
                            {(activeProfession === "" && profession === "") && <p className="text-lg text-dark">Select a profession on the left sidebar or hover over the plot to explore demographic details</p>}
                        </div>
                    </div>

                </div>
            </div>
            <p className={"mt-6 font-bolder"}>How to interpret the plot</p>
            <p className={"mb-3"}>In general, masks have a higher probability of being replaced by "he" than by "she" (this can be seen by changing the X-axis). We can say the model generally thinks in masculine when there is no
                gender information in the sentence.</p>

            <p className={"mb-3"}>Male-dominated professions continually correspond to a low probability for the mask being replaced by "she".</p>

            <p>However, there is not a clear pattern that relates female-dominated professions with a high probability for "she". Nevertheless, whenever the model outputs a high probability for "she",
                it is, for our dataset, in all cases for a female-dominated profession. We hypothesize the model only learns to deviate from its default masculine assumption for professions with a very strong female connotation.
                Those obtaining the highest probability are: <strong>nurse, maid, housekeeper, stewardess, receptionist and secretary</strong>.
            </p>
        </div>

    </section>
};

export default BiasLMs;
