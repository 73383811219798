import "./styles.scss";
import React from "react";
import bias from "../imgs/bias.jpg";

const References = () => {
    return <section className="mt-6">
        {/* <!-- Content --> */}
        <div
            className="container-lg max-w-screen-xl position-relative text-lg-start mt-3 mb-20">
            <hr className="mt-16 mb-7" style={{height: "1px", color: "#000000"}}/>
            <h1 className="text-md mb-4">References</h1>
                    <p className="text-sm mb-2">{"Figures for occupation demographics are obtained from the USA Labor Force Statistics. Some professions were manually replaced by a synonym to ensure all of them could be written with one word. Detailed information can be found here: https://www.bls.gov/cps/cpsaat11.htm"}</p>
                    <p className="text-sm mb-2">{"Liu, Qi, et al. \"A survey on contextual embeddings.\" arXiv preprint arXiv:2003.07278 (2020)."}</p>
                    <p className="text-sm mb-2">{"Gupta, Umang, et al. \"Mitigating Gender Bias in Distilled Language Models via Counterfactual Role Reversal.\" arXiv preprint arXiv:2203.12574 (2022)."}</p>
                    <p className="text-sm mb-2">{"Garg, Nikhil, et al. \"Word embeddings quantify 100 years of gender and ethnic stereotypes\" Proceedings of the National Academy of Sciences (2018)"}</p>
                    <p className="text-sm mb-2">{"Rudinger, Rachel, et al. \"Gender Bias in Coreference Resolution\" Proceedings of the 2018 Conference of the North American Chapter of the Association for Computational Linguistics (2018)"}</p>
                    <p className="text-sm mb-2">{"Devlin, Jacob, et al. \"Bert: Pre-training of deep bidirectional transformers for language understanding.\" arXiv preprint arXiv:1810.04805 (2018)."}</p>
                    <p className="text-sm mb-2">{"Bolukbasi, Tolga, et al. \"Man is to computer programmer as woman is to homemaker? debiasing word embeddings.\" Advances in neural information processing systems 29 (2016)"}</p>
                    <p className="text-sm mb-2">{"Vaswani, Ashish, et al. \"Attention is all you need.\" Advances in neural information processing systems 30 (2017)."}</p>
                    <p className="text-sm mb-2">{"Brown, Tom, et al. \"Language models are few-shot learners.\" Advances in neural information processing systems 33 (2020): 1877-1901."}</p>
                    <p className="text-sm mb-2">{"Liang, Paul Pu, et al. \"Towards understanding and mitigating social biases in language models.\" International Conference on Machine Learning. PMLR, 2021."}</p>
                    <p className="text-sm mb-2">{"European Parliament. \"Recent trends in female employment\""}</p>
                    <p className="text-sm mb-2">{"Swinger, Nathaniel, et al. . \"What are the biases in word embeddings?\"In Proceedings of the 2019 AAAI/ACM Conference on AI, Ethics, and Society, pages 305–311, 2019"}</p>
                    <p className="text-sm mb-2">{"Pearce, A. \"What have language models learned?\" In VISxAI 2021"}</p>
        </div>
    </section>
};

export default References;