import React, {useEffect, useState} from 'react';
import './App.scss';
import Header from "./components/Header";
import DynamicGenderBias from "./components/DynamicGenderBias";
import Introduction from "./components/Introduction";
import References from "./components/References";
import useGetStaticEmbeddings from "./backend/useGetStaticEmbeddings";
import useGetCensusData from "./backend/useGetCensusData";
import useGetProbabilitiesData from "./backend/useGetProbabilitiesData";
import BiasLMs from "./components/BiasLMs";
import DynamicLineGenderBias from "./components/DynamicLineGenderBias";
import DynamicLinearRegressionBias from './components/DynamicLinearRegressionBias';
import Discussion from "./components/Discussion";

function App() {

    const [loadingEmbeddings, errorEmbeddings, embeddings, availableLayers, availableAlgorithms, availableProfessions, getEmbeddings, resetEmbeddings] = useGetStaticEmbeddings();
    const [loadingCensus, errorCensus, census, getCensus, resetCensus] = useGetCensusData();
    const [loadingProbs, errorProbs, probs, getProbs, resetProbs] = useGetProbabilitiesData();
    const [profession, setProfession] = useState("engineer");

    // Get data
    useEffect(() => {
        getEmbeddings();
        getCensus();
        getProbs();
    }, []);

    return (
        <>
            <Header/>
            <Introduction census={census} loadingCensus={loadingCensus} profession={profession} setProfession={setProfession}/>
            {embeddings.length > 0 && !loadingEmbeddings && <BiasLMs embeddings = {embeddings} probs={probs} loadingProbs={loadingProbs} census={census} loadingCensus={loadingCensus} profession={profession} setProfession={setProfession}/>}
            {embeddings.length > 0 && !loadingEmbeddings &&
            <DynamicGenderBias embeddings={embeddings} allLayers={availableLayers}
                               allAlgorithms={availableAlgorithms} profession={profession} setProfession={setProfession}/>}
            <div style={{paddingTop: "3em"}}></div>
            {embeddings.length > 0 && !loadingEmbeddings && <DynamicLineGenderBias embeddings={embeddings} allLayers={availableLayers}
                       allAlgorithms={[...availableAlgorithms, "embedding"]} profession={profession}/>}
            {/*embeddings.length > 0 && !loadingEmbeddings &&
            <DynamicLinearRegressionBias embeddings={embeddings} allLayers={availableLayers}
            allAlgorithms={availableAlgorithms} allProfessions={availableProfessions}/>*/}
            <Discussion/>
            <References/>
        </>)
}

export default App;
