export interface queryBackendProps {
    route: string;
}

export const BASE_URL = "https://backend.lm-bias.lingvis.io";

export const queryBackend = async (route: string) => {
    const requestURL = `${BASE_URL}/${route}`;
    // const formData = new FormData();
    const data = await fetch(requestURL,
        {
            method: 'GET',
            headers: {"Content-Type": "application/json"},
        }
    ).then(response => response.json());

    return data;
}


export default queryBackend;


