import "./styles.scss";

const Header = () => {
    return <section className="position-relative pt-20 pb-20 bg-cover bg-size--cover header-background">
        {/* Overlay */}
        <span className="position-absolute top-0 start-0 w-full h-full bg-black opacity-40"></span>
        {/* <!-- Content --> */}
        <div
            className="container-lg max-w-screen-xl position-relative overlap-10 text-center text-lg-start pt-5 pb-5 pt-lg-6">
            <div className="row row-grid align-items-center">
                <div className="col-lg-8 text-center text-lg-start">
                    {/*<!-- Title -->*/}
                    <h1 className="ls-tight font-bolder display-5 text-white mb-5">
                        How is Real-World Gender Bias Reflected in Language Models?

                    </h1>
                    {/*<!-- Text -->*/}
                    <p className="lead text-white text-opacity-80 w-lg-2/3">
                    This dashboard explores what language models have learned about gender as it relates to professions. Are they biased? Does this bias align with real-world demographics?
                    </p>
                    <p className="text-white mt-4 w-lg-3/3">
                       Javier Rando<sup>1</sup> &ensp; Alexander Theus<sup>1</sup> &ensp; Rita Sevastjanova<sup>2</sup> &ensp; Menna El-Assady<sup>1</sup>
                    </p>
                    <p className="text-white mt-1 text-sm mb-10 w-lg-2/3">
                        <sup>1</sup>ETH Zurich &emsp; <sup>2</sup>University of Konstanz
                    </p>
                </div>
            </div>
        </div>
    </section>
};

export default Header;